<template>
  <div>
    <b-card
      no-body
    >
      <b-table
        fixed
        striped
        small
        hover
        :fields="fields"
        :items="requests"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(photo)="data">
          <b-avatar
            :src="data.value"
          /></template>
        <template #cell(full_name)="data">
          <div class="font-weight-bolder">
            {{ data.item.full_name }}
          </div>
          <span class="font-small-2">{{ data.item.email }}</span>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
  import {
    BTable, BAvatar, BCard,
  } from 'bootstrap-vue'

  export default {
    inject: ['requestsRepository'],
    components: {
      BCard,
      BTable,
      BAvatar,
    },
    data() {
      return {
        fields: [
          {
            key: 'photo',
            label: 'Foto',
          },
          {
            key: 'full_name',
            label: 'Nombre',
            formatter: (value, key, item) => `${item.first_name} ${item.last_name}`,
            filterByFormatted: true,
          },
          {
            key: 'nid',
            label: 'Cédula',
          },
          {
            key: 'profession',
            label: 'Tipo de profesión',
          },
          {
            key: 'area',
            label: 'Área asignada',
          },
          {
            key: 'request',
            label: 'Solicitud',
          },
        ],
        requests: [],
        filter: null,
        filterOn: [],
      }
    },

    async mounted() {
      const me = this
      const result = await me.requestsRepository.getAll()

      me.requests = result.data
    },
  }
</script>

<style lang="scss" scoped>

</style>
